import React from "react";
import './logo.css';
import Pic from '../assets/rentmoneylogo.png'


const Logo = () => {
    return (
           
            <div className="logo-container">

            <div className="title">Rent Money</div>

                <div className="img-container">
                    <img className="img" src={Pic} alt=""/>
                </div>
            </div>

        


    )
}




export default Logo;