import React from "react";
import NavBar from './components/navbar';
import Logo from './components/logo';

function App() {
  return (
    <>
      <NavBar/>
      <Logo/>
    </>
  );
}

export default App;
